import React from "react"
import {Helmet} from "react-helmet";
import { Layout } from '../components/Layout';

import './index.scss';

const joulupukki = `
[Verse 1] (Children)
C           F           G7          C
Joulupukki, joulupukki, valkoparta, vanha ukki
C           F             G7                C
Eikö taakka paina selkää? Käypä tänne, emme pelkää!
C             Dm           G7           C
Oothan meille vanha tuttu, puuhkalakki, karvanuttu
Am              F              G7                 C
Tääl on myöskin kiltit lapset, kirkassilmät, silkohapset
  
[Verse 2] (Children)
C           F           G7          C
Joulupukki, joulupukki, valkoparta, vanha ukki
C           F           G7                 C
Vietä iltaa joukossamme täällä meidän riemunamme
C           Dm           G7           C
Tervetullut meille aina, käypä tänne, puuta paina—
Am               F             G7                  C
Tai jos leikkiin tahdot tulla, kahta hauskempaa on sulla!
  
[Verse 3] (Santaclaus)
C               F              G7            C
Kiitos, kiitos, kiltit lapset, kirkassilmät, silkohapset
C             F            G7            C
Terve teille, pienokaiset, leikkiväiset, laulavaiset!
C               Dm         G7            C
Hauska tääl ois iltahetki, vaan on vielä pitkä retki:
Am           F             G7                  C
Kuusen luota kuusen luokse mulla matkan suunta juoksee
  
[Verse 4] (Children)
C           F           G7          C
Joulupukki, joulupukki, valkoparta, vanha ukki
C           F            G7                 C
Viivy vielä pieni hetki, vaikka onkin pitkä retki
C            Dm           G7             C
Tässä tuomme sulle mettä, vilpoisaa kuin lähdevettä
Am            F            G7               C
Sitten jaksat pitkän tiesi kulkea kuin aimo miesi
  
[Verse 5] (Santaclaus)
C               F              G7            C
Kiitos vaan nyt kiltit lapset, kirkassilmät, silkohapset! 
C             F            G7                C
Täs on teille muistiaiset, pienet joulumaistiaiset
  
[Verse 6] (Children)
C             F          G7        C
Kiitos sulle, oiva ukki, ystävämme joulupukki
C                 F             G7                 C
Taas kun päästään ensi jouluun, tervetullut meidän kouluun!
`;

const jouluontaas = `
[Verse 1]
D
Joulu on taas, joulu on taas
A7              D
Kattilat täynnä puuroo
D
Joulu on taas, joulu on taas
A7              D
Kattilat täynnä puuroo
G             D
Nyt sitä saa, nyt sitä saa
A7               D
Vatsansa täyteen puuroo
G             D
Nyt sitä saa, nyt sitä saa
A7               D
Vatsansa täyteen puuroo
 
[Verse 2]
D
Joulu on taas, joulu on taas
A7                  D
Voi, kuinka meil on hauskaa!
D
Joulu on taas, joulu on taas
A7                  D
Voi, kuinka meil on hauskaa!
G            D
Lapsilla on, lapsilla on
A7             D
Aamusta iltaan hauskaa
G            D
Lapsilla on, lapsilla on
A7             D
Aamusta iltaan hauskaa
`

const tonttujen = `
Tonttujen jouluyö (tiptap)
Tomtarnas julnatt

   D                                   A7   D  A7
1. Soihdut sammuu, kaikki väki nukkuu, väki nukkuu.

Em                                G  D  A7 D
öitten varjoo talon touhu hukkuu, touhu hukkuu.

D                                    A7        D
Tip-tap, tiptap, tipe, tipe, tiptap, tip, tip, tap.


2. Tonttujoukko silloin varpahillaan, varpahillaan,
varovasti hiipii alta sillan, alta sillan.
Tip-tap...

3. Jouluruokaa tarjoo kunnon väki, kunnon väki,
raoistansa sen jo tonttu näki, tonttu näki.
Tip-tap...

4. Pöydän päälle veitikkaiset rientää, veitikkaiset,
syövat paistia ja juovat lientä, juovat lientä.
Tip-tap...

5. Herkkua on siinä monenlaista, monenlaista,
kuiske kuuluu: "Miltä ruoka maistaa, ruoka mistaa?"
Tip-tap...

6. Sitten leikitellään kuusen alla, kuusen alla,
kunnes päivä koittaa taivahalla, taivahalla,
Tip-tap...

7. Hiljaa hiipii joukko varpahillaan, varpahillaan,
kotikoloihinsa alle sillan, alle sillan.
Tip-tap...

`

const porsaita = `
Porsaita äidin oomme kaikki

C
Porsaita äidin oomme kaikki
G               C
Oomme kaikki, Oomme kaikki
C
Porsaita äidin oomme kaikki
G               C
Oomme kaikki, kaikki

C     G  C
Sinä ja miiinää.

Morsgrisar är vi allihopa,
allihopa, allihopa.
Morsgrisar är vi allihopa,
allihopa, du mä'.
Ja mä', å du mä'.
Ja mä' och du mä'.
`

const koskameillä = `
KOSKA MEILLÄ ON JOULU

C                G
Koska meillä on joulu
              C
Juhla armas lapsien
C           G
Eikä rasita koulu
              C
Syy on olla iloinen!

Jne. jne. :))
`

const kulkuset = `
[Verse 1]
D                   Em                  A7         F#m7   Bm7  E7           A7
Lunta tulvillaan on raikas talvisää, ei liinakkommekaan,  nyt  enää talliin jää. 
    D                    Em                Em     A7      F#m7      Bm7    E7                A7
Sen kohta valjastan reen pienen etehen, ja sitten joutuin matka  -  han me käymme riemui  -  ten. 
D         Dmaj7   D6        Dmaj7  D       G        Gm      D
Kulku  -  set,    kulku  -  set    riemuin helk  -  käi  -  lee. 
Em7    A7        D         Bm7     E7               A7
Talven valko  -  hiuta  -  leet ne kilvan leijai  - lee. 
D             Dmaj7  D6       Dmaj7  D     G       Gm      D
Re  -  ke  -  hen,   reke  -  hen    nouse mat  -  kaa  -  maan! 
Em7  A7   D              H7     Em7    D
Lumi alla ja  -  las  -  ten se laulaa lauluaan. 
D   G                       D                     E7                    A7
On  ryijyn alla lämmin, kun lunta tuiskuttaa. Nyt liinakkomme kiitää ja valkoinen on maa. 
D         Dmaj7   D6        Dmaj7  D       G        Gm      D
Kulku  -  set,    kulku  -  set    riemuin helk  -  käi  -  lee. 
Em7    A7        D         Bm7     E7               A7
Talven valko  -  hiuta  -  leet ne kilvan leijai  - lee. 
 
 
[Verse 2]
   D                  Em                A7           F#m7     Bm7 E7          A7
Toiseen maailmaan tää retki meidät vie, niin puhtaan valkeaan käy liinakkomme tie. 
     D                  Em                 Em A7        F#m7      Bm7 E7            A7
Taas tuulen huminaa nyt puiden latvat soi. En retkeämme unhoittaa kai milloinkaa mä voi. 
D         Dmaj7   D6        Dmaj7  D       G        Gm      D
Kulku  -  set,    kulku  -  set    riemuin helk  -  käi  -  lee. 
Em7    A7        D         Bm7     E7               A7
Talven valko  -  hiuta  -  leet ne kilvan leijai  - lee. 
D             Dmaj7  D6       Dmaj7  D     G       Gm      D
Re  -  ke  -  hen,   reke  -  hen    nouse mat  -  kaa  -  maan! 
Em7  A7   D              H7     Em7    D
Lumi alla ja  -  las  -  ten se laulaa lauluaan. 
D   G                       D                     E7                    A7
On  ryijyn alla lämmin, kun lunta tuiskuttaa. Nyt liinakkomme kiitää ja valkoinen on maa. 
D         Dmaj7   D6        Dmaj7  D       G        Gm      D
Kulku  -  set,    kulku  -  set    riemuin helk  -  käi  -  lee. 
Em7    A7        D         Bm7     E7               A7
Talven valko  -  hiuta  -  leet ne kilvan leijai  - lee.
`;

const JouluPage = () => (
  <>
  <Helmet>
    <title>Wirmark</title>
  </Helmet>
  <Layout className="Home">
    <section className="Section">
      <h1>Joululauluja</h1>
      <h2>1. Joulupukki</h2>
      <pre>{joulupukki}</pre>
    </section>
    <section className="Section">
      <h2>2. Joulu on taas</h2>
      <pre>{jouluontaas}</pre>
    </section>
    <section className="Section">
      <h2>3. Tonttujen jouluyö</h2>
      <pre>{tonttujen}</pre>
    </section>
    <section className="Section">
      <h2>4. Porsaita äidin oomme kaikki</h2>
      <pre>{porsaita}</pre>
    </section>
    <section className="Section">
      <h2>5. Koska meillä on joulu</h2>
      <pre>{koskameillä}</pre>
    </section>
    <section className="Section">
      <h2>6. Kulkuset</h2>
      <pre>{kulkuset}</pre>
    </section>
  </Layout>
  </>
)

export default JouluPage
